<template>
  <v-snackbar
    v-for="(snackbar, index) in snackbars"
    :key="snackbar.key"
    :timeout="calculateTimeout(snackbar)"
    :color="snackbar.type"
    :title="snackbar.title"
    position="sticky"
    :model-value="true"
    :style="{ bottom: `${index * 90}px` }"
    @update:model-value="() => close(snackbar.key!)"
  >
    <v-list-item
      class="pa-0"
      :title="getTitle(snackbar)"
      :subtitle="snackbar.text"
      :prepend-icon="
        snackbar.type === 'success'
          ? 'check_circle'
          : snackbar.type === 'error'
            ? 'error'
            : snackbar.type === 'warning'
              ? 'warning'
              : 'info'
      "
    />

    <v-progress-linear v-if="snackbar.pending" class="position-absolute" indeterminate />

    <template #actions>
      <v-btn v-if="snackbar.closable" @click="() => close(snackbar.key!)">
        {{ $t("close") }}
      </v-btn>
      <v-btn v-for="(action, actionIndex) in snackbar.actions" :key="actionIndex" @click="action.handler">
        {{ action.label }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
const props = defineProps<{ snackbars: Array<Snackbar> }>()
const { snackbars } = toRefs(props)

const close = (key: string) => {
  const index = snackbars.value.findIndex((snackbar) => snackbar.key === key)
  snackbars.value.splice(index, 1)
}

/**
 * The timeout only resets if the value is actually changed,
 * so setting 5000 multiple times will not reset the timeout.
 * Add the count so the timeout is reset when the count changes.
 * 5000 is the default vuetify timeout if no timeout is set.
 */
const calculateTimeout = (snackbar: Snackbar) => (snackbar.timeout ?? 5000) + (snackbar.count ?? 0)
const getTitle = (snackbar: Snackbar) => `${snackbar.title}${snackbar.count ? ` (${snackbar.count}x)` : ""}`
</script>
